
import GPricingCardFeatures from './g-pricing-card-features';
import GLazyImage from '../g-lazy-image';
import GLottieAnimation from '../g-lottie-animation';
import GButtonV2 from '../g-button-v2';
import Intersect from '~/utils/intersect';
import smoothScrollPolyfill from '~/utils/smoothScrollPolyfill';

export default {
  name: 'g-pricing-card',
  components: {
    GLazyImage,
    GButtonV2,
    IconCog: () => import('assets/svg/icons/icon-cog.svg'),
    IconDropdown: () => import('assets/svg/icons/icon-dropdown.svg'),
    Intersect,
    GLottieAnimation,
    GPricingCardFeatures,
  },
  mixin: [smoothScrollPolyfill],
  props: {
    plans: {
      type: Array,
      required: true,
      default: () => [],
    },
    features: {
      type: Array,
      required: true,
      default: () => [],
    },
    mostPopular: {
      type: String,
      default: 'Premium',
    },
    maxFeatures: {
      type: String,
      default: '18',
    },
    countryCode: {
      type: String,
      default: 'us',
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMoreOpen: false,
      stickyHeader: false,
      scrollHeader: null,
      scrollContainer: null,
      scrollScroller: null,
      scrolledContainer: 'container',
      cardWidth: 0,
    };
  },
  computed: {
    totalPlan() {
      return {
        '--max-width':
          (this.plans.length === 3 ? '385' : this.isDesktop ? '280' : '230') +
          'px',
      };
    },
  },
  mounted() {
    this.scrollHeader = document.querySelector(
      '.g-pricing-card__sticky-header'
    );
    this.scrollScroller = document.querySelector(
      '.g-pricing-card__scroll-inner'
    );
    this.scrollContainer = document.querySelector(
      '.g-pricing-card__container-cards'
    );
    this.cardWidth = this.$refs['g-pricing-card'][0].offsetWidth + 'px';
  },
  methods: {
    scroll(event, $type) {
      const scrollTo = event.target.scrollLeft;
      if ($type === 'container' && this.scrolledContainer === 'container') {
        this.scrollScroller.scrollLeft = scrollTo;
        this.scrollHeader.scrollLeft = scrollTo;
      } else if (
        $type === 'scroller' &&
        this.scrolledContainer === 'scroller'
      ) {
        this.scrollContainer.scrollLeft = scrollTo;
        this.scrollHeader.scrollLeft = scrollTo;
      }
    },
    swipeHandler(container) {
      return (direction, event) => {
        this.scrolledContainer = container;
      };
    },
    getButtonColor(plan) {
      const purple = {};
      const white = {
        color: 'azure-10',
        secondaryColor: 'purple-50',
      };
      if (plan === 'Enterprise') {
        return !this.isDesktop ? purple : white;
      }
      if (plan === 'Starter') {
        return !this.isDesktop ? purple : white;
      }
      return !this.isDesktop ? purple : plan === 'Premium' ? purple : white;
    },
    showHeader(value) {
      this.stickyHeader = value;
    },
    showMore(plan) {
      if (plan !== 'Enterprise') {
        this.showMoreOpen = !this.showMoreOpen;
        if (!this.showMoreOpen) {
          window.scrollTo({
            top: this.$refs.pricingCard.offsetTop - 150,
            behavior: 'smooth',
          });
        }
      }
    },
    getShowMoreText(plan) {
      if (plan === 'Enterprise') {
        return {
          link: `/${this.countryCode}/enterprise`.replace('/us', ''),
          text: 'Learn more',
        };
      }
      const moreText = this.isDesktop ? 'more' : 'features';
      return {
        text: `Show ${this.showMoreOpen ? 'less' : moreText}`,
        link: null,
      };
    },
  },
};
