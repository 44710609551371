
import GTourNumber from './g-tour-number';
import GScrollTo from '../g-scroll-to';

export default {
  name: 'g-tour-stations',
  components: {
    GTourNumber,
    GScrollTo,
  },
  props: {
    stations: {
      type: Array,
      required: true,
    },
    currentBucket: {
      type: Number,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
