
import GButtonV2 from '../g-button-v2';
export default {
  name: 'g-newsletter',
  components: {
    IconNewsletter: () => import('assets/svg/icons/icon-newsletter.svg'),
    GButtonV2,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    ctaText: {
      type: String,
      default: 'Subscribe now',
    },
  },
};
