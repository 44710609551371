
import GContainer from '../g-container';
import GLazyImage from '../g-lazy-image';
import modalControl from 'mixins/modalControl';

export default {
  name: 'g-media-on-page',
  components: {
    GContainer,
    GLazyImage,
    WaveDownVectorNoColor: () =>
      import('assets/svg/squiggle/wave-down-vector-no-color.svg'),
    WaveUp: () => import('assets/svg/squiggle/wave-up.svg'),
  },
  mixins: [modalControl],
  props: {
    heading: {
      type: String,
      required: true,
    },
    heading2: {
      type: String,
      default: '',
    },
    videoEmbedUrl: {
      type: String,
      default: '',
    },
    desktopVideoThumbnail: {
      type: Object,
      required: true,
    },
    mobileVideoThumbnail: {
      type: Object,
      default: null,
    },
    tag: {
      type: String,
      default: '',
    },
    overhangHeight: {
      type: Number,
      default: 300,
    },
    isFluidOverhang: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: 'purple',
    },
  },
  computed: {
    iframeSrc() {
      return this.isShown ? this.videoEmbedUrl : '';
    },
    resolveMobileVideoThumbnail() {
      return this.mobileVideoThumbnail
        ? this.mobileVideoThumbnail
        : this.desktopVideoThumbnail;
    },
    headingColor() {
      return ['white', 'yellow', 'peach'].includes(this.theme)
        ? 'azure-60'
        : 'grey-10';
    },
  },
};
