
import GLazyImage from '../g-lazy-image';

export default {
  name: 'g-quote-with-image',
  components: {
    GLazyImage,
  },
  props: {
    quoteContent: {
      required: true,
      type: String,
    },
    author: {
      type: String,
      required: true,
    },
    authorPosition: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: false,
      default: null,
    },
  },
  created() {
    if (this.image) {
      this.image.desktopWidth = '130';
      this.image.mobileWidth = '130';
    }
  },
};
