
export default {
  name: 'g-preloader',
  props: {
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedColor() {
      const themeColors = {
        white: 'grey-10',
        yellow: 'grey-10',
        peach: 'grey-10',
        azure: 'azure-60',
        pink: 'purple-50',
        purple: 'azure-60',
        enterprise: 'azure-60',
      };
      return this.color ?? themeColors[this.theme];
    },
  },
};
