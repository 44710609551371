
import GSpacer from '../g-spacer';
import GTourStations from './g-tour-stations';
import GTourCard from './g-tour-card';
const throttle = () => import('lodash/throttle');

export default {
  name: 'g-product-tour',
  components: {
    GSpacer,
    GTourStations,
    GTourCard,
  },
  props: {
    cards: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      bucketIndex: 0,
      els: [],
      scrollTop: 0,
    };
  },
  mounted() {
    this.setupElWatchers();
    throttle().then(fn => {
      window.addEventListener('scroll', fn.default(this.onScroll, 100));
    });
  },
  methods: {
    setupElWatchers() {
      this.els = this.cards.map(card => {
        return {
          station: document.getElementById(`station-${card.scroll_to_target}`),
          bucket: document.getElementById(`bucket-${card.scroll_to_target}`),
        };
      });
    },
    onScroll() {
      this.scrollTop =
        window.pageYOffset ||
        /* istanbul ignore next */ document.documentElement.scrollTop;

      this.updateBucketIndex();
    },

    /* istanbul ignore next */
    stationIsWithinBucketBoundaries(el, offset) {
      const stationRect = el.station.getBoundingClientRect();
      const bucketRect = el.bucket.getBoundingClientRect();
      const stationTop = Math.round(this.scrollTop + stationRect.top);
      const stationBottom = Math.round(this.scrollTop + stationRect.bottom);
      const bucketTop = Math.round(this.scrollTop + bucketRect.top - offset);
      const bucketBottom = Math.round(
        this.scrollTop + bucketRect.bottom - offset
      );

      return stationTop >= bucketTop && stationBottom <= bucketBottom;
    },

    /* istanbul ignore next */
    updateBucketIndex() {
      const offset = this.$isMobile ? this.$store.state.menu.headerOffset : 300;
      for (let index = 0; index < this.els.length; index++) {
        if (this.stationIsWithinBucketBoundaries(this.els[index], offset)) {
          this.bucketIndex = index;
        }
      }
    },
  },
};
