
export default {
  name: 'g-media',
  components: {
    GLazyImage: () => import('../g-lazy-image'),
    GImageAnimation: () => import('../g-image-animation'),
    GLottieAnimation: () => import('../g-lottie-animation'),
    GVideoIframe: () => import('../g-video-iframe'),
  },
  props: {
    // image props
    image: {
      type: Object,
      default: null,
    },
    loadImmediately: {
      type: Boolean,
      default: null,
    },

    // image animation props
    images: {
      type: Array,
      default: null,
    },
    layout: {
      type: String,
      default: null,
    },

    // lottie props
    animationUrl: {
      type: String,
      default: '',
    },
    mobileAnimationUrl: {
      type: String,
      default: null,
    },
    placeholderImage: {
      type: Object,
      default: null,
    },
    loadPlaceholderImmediately: {
      type: Boolean,
      default: null,
    },

    // video props
    videoEmbedUrl: {
      type: String,
      default: '',
    },
    videoThumbnail: {
      type: Object,
      default: null,
    },
    playVideoOption: {
      type: String,
      default: null,
    },
    imageSizeSm: {
      type: Number,
      default: null,
    },
    imageSizeMd: {
      type: Number,
      default: null,
    },
    imageSizeLg: {
      type: Number,
      default: null,
    },
    imageSizeXl: {
      type: Number,
      default: null,
    },
    imageSizeXxl: {
      type: Number,
      default: null,
    },
  },
};
