
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';
import GPricingCardFeatures from './g-pricing-card-features';
import smoothScrollPolyfill from '~/utils/smoothScrollPolyfill';

export default {
  name: 'g-pricing-card-small',
  components: {
    GLazyImage,
    GButtonV2,
    IconDropdown: () => import('assets/svg/icons/icon-dropdown.svg'),
    GPricingCardFeatures,
  },
  mixin: [smoothScrollPolyfill],
  props: {
    plans: {
      type: Array,
      required: true,
      default: () => [],
    },
    features: {
      type: Array,
      required: true,
      default: () => [],
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showMoreOpen: false,
    };
  },
  methods: {
    showMore() {
      this.showMoreOpen = !this.showMoreOpen;
      if (!this.showMoreOpen) {
        window.scrollTo({
          top: this.$refs.pricingCardSmall.offsetTop - 150,
          behavior: 'smooth',
        });
      }
    },
  },
};
