
import GLazyImage from '../g-lazy-image';
import GButtonV2 from '../g-button-v2';
import GContainer from '../g-container';
import modalControl from 'mixins/modalControl';

export default {
  name: 'g-light-bg-testimonial',
  components: {
    GLazyImage,
    GButtonV2,
    waveUpVector: () => import('assets/svg/squiggle/wave-up-vector.svg'),
    waveDownVector: () => import('assets/svg/squiggle/wave-down-vector.svg'),
    GContainer,
    videoPlayBtn: () => import('assets/svg/icons/video-play-btn.svg'),
  },
  mixins: [modalControl],
  props: {
    quote: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    authorTitle: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: '',
    },
    buttonLink: {
      type: String,
      required: false,
      default: '',
    },
    videoThumbnail: {
      type: Object,
      required: true,
    },
    videoEmbedUrl: {
      type: String,
      required: false,
      default: '',
    },
    poscastButtonText: {
      type: String,
      required: false,
      default: '',
    },
    poscastButtonLink: {
      type: String,
      required: false,
      default: '',
    },
    signupCtaId: {
      type: String,
      default: 'light-bg-testimonial',
    },
  },
  computed: {
    textOrQuoteClasses() {
      return {
        'g-light-bg-testimonial__quote': this.quote,
        'g-light-bg-testimonial__text': this.text,
      };
    },
    authorDetails() {
      return this.author && this.authorTitle
        ? this.author + ', ' + this.authorTitle
        : this.author;
    },
    iframeSrc() {
      return this.isShown ? this.videoEmbedUrl : '';
    },
  },
};
