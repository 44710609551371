
import GSpacer from '../g-spacer/g-spacer';
import GContainer from '../g-container/g-container';

export default {
  name: 'g-quote',
  components: { GContainer, GSpacer },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
};
