
import GContainer from '../g-container';
import GButtonV2 from '../g-button-v2';

export default {
  name: 'g-pricing-on-page',
  components: {
    GContainer,
    GButtonV2,
  },
  props: {
    plan: {
      type: String,
      required: true,
      validator: plan => {
        return [
          'premium',
          'scheduling',
          'time_attendance',
          'flexi',
          'employee_onboarding',
        ].includes(plan);
      },
      default: 'premium',
    },
    pricingCountry: {
      type: String,
      default: 'us',
    },
    region: {
      type: String,
      default: 'us',
    },
    pricing: {
      type: Object,
      required: true,
      validator: pricing => {
        return (
          pricing.amer_cost_per_user &&
          pricing.apac_cost_per_user &&
          pricing.emea_cost_per_user &&
          pricing.amer_cost_per_user_monthly &&
          pricing.apac_cost_per_user_monthly &&
          pricing.emea_cost_per_user_monthly &&
          pricing.amer_cost_per_user_flexi &&
          pricing.apac_cost_per_user_flexi &&
          pricing.emea_cost_per_user_flexi &&
          pricing.emea_cost_per_inactive_user_flexi &&
          pricing.apac_cost_per_inactive_user_flexi &&
          pricing.amer_cost_per_inactive_user_flexi &&
          pricing.emea_tax &&
          pricing.amer_tax &&
          pricing.apac_tax &&
          pricing.amer_cost_per_user_scheduling &&
          pricing.amer_cost_per_user_time_attendance &&
          pricing.apac_cost_per_user_scheduling &&
          pricing.apac_cost_per_user_time_attendance &&
          pricing.emea_cost_per_user_scheduling &&
          pricing.emea_cost_per_user_time_attendance &&
          pricing.apac_cost_per_user_employee_onboarding &&
          pricing.emea_cost_per_user_employee_onboarding &&
          pricing.amer_cost_per_user_employee_onboarding
        );
      },
    },
    heading: {
      type: String,
      required: true,
    },
    secondHeading: {
      type: String,
      required: false,
      default: '',
    },
    colourOverlap: {
      type: Boolean,
      default: false,
    },
    linkText: {
      type: String,
      required: true,
    },
    displayableTerms: {
      type: String,
      required: false,
      default: '',
    },
    taxTerms: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      regions: {
        us: {
          currency: 'USD',
          symbol: '$',
          cents: 'c',
          pricingKeyPrefix: 'amer',
        },
        gb: {
          currency: 'GBP',
          symbol: '£',
          cents: 'p',
          pricingKeyPrefix: 'emea',
        },
        au: {
          currency: 'AUD',
          symbol: '$',
          cents: 'c',
          pricingKeyPrefix: 'apac',
        },
      },
      // these countries have VAT
      vatCountries: [
        'BE',
        'BG',
        'CZ',
        'DK',
        'DE',
        'EE',
        'IE',
        'EL',
        'ES',
        'FR',
        'HR',
        'IT',
        'CY',
        'LV',
        'LT',
        'LU',
        'HU',
        'MT',
        'NL',
        'AT',
        'PL',
        'PT',
        'RO',
        'SI',
        'SK',
        'FI',
        'SE',
      ],
    };
  },
  computed: {
    employeeOnboardingPlan() {
      return this.plan === 'employee_onboarding';
    },
    otherPlans() {
      if (
        this.plan === 'premium' ||
        this.plan === 'scheduling' ||
        this.plan === 'time_attendance'
      ) {
        return true;
      }
      return false;
    },
    employeeOnboardingTaxTerm() {
      if (this.plan === 'employee_onboarding') {
        return `
      ${this.regional.currency} ${this.taxTerms} <br />
      ${this.displayableTerms}
      `;
      }
      return '';
    },
    regional() {
      if (['au', 'gb'].includes(this.pricingCountry)) {
        return this.regions[this.pricingCountry];
      }
      return this.regions['us'];
    },
    pricingKey() {
      const pricingKeySuffix =
        this.plan === 'premium' ? '_monthly' : `_${this.plan}`;
      return `${this.regional.pricingKeyPrefix}_cost_per_user${pricingKeySuffix}`;
    },
    price() {
      return this.pricing[this.pricingKey];
    },
    flexiInactiveUserCost() {
      return this.pricing[
        `${this.regional.pricingKeyPrefix}_cost_per_inactive_user_flexi`
      ];
    },
    pricingUrl() {
      const regionPrefix = ['au', 'gb'].includes(this.region)
        ? `/${this.region}`
        : '';
      return `${regionPrefix}/pricing`;
    },
  },
  methods: {
    formatPrice(price) {
      if (price.includes('.')) {
        const priceNumeric = Number(price);
        if (priceNumeric < 1) {
          return priceNumeric * 100 + this.regional.cents;
        } else {
          return this.regional.symbol + Number(price).toFixed(2);
        }
      }
      return this.regional.symbol + price;
    },
  },
};
