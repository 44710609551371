
export default {
  name: 'g-pricing-card-features',
  components: {
    IconCheck: () => import('assets/svg/icons/icon-check.svg'),
    IconClose: () => import('assets/svg/icons/icon-close.svg'),
  },
  props: {
    features: {
      type: Array,
      required: true,
      default: () => [],
    },
    planName: {
      type: String,
      required: true,
    },
    showMoreOpen: {
      type: Boolean,
      default: false,
    },
    maxFeatures: {
      type: String,
      default: '18',
    },
    isDesktop: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    displayFeatures() {
      if (!this.isDesktop) {
        return this.showMoreOpen;
      }
      return true;
    },
  },
  methods: {
    getFeatures(plan) {
      const features = this.features.filter(feat => {
        feat.show = feat.plan.includes(plan);
        if (plan === 'Enterprise') {
          // we should only display the features that are only for Enterprise
          return feat.plan.length === 1 && feat.plan[0] === plan;
        }
        if (plan === 'Starter') {
          return (
            feat.plan.includes('Starter') ||
            (feat.plan.includes('Premium') && !feat.hidden.includes(plan))
          );
        }
        // is not an Enterprise feature and if a freemium feature, another plan is also checked
        // freemium only feature should not be displayed on other plan
        return (
          !feat.plan.includes('Enterprise') &&
          ((feat.plan.includes('Starter') && feat.plan.length !== 1) ||
            !feat.plan.includes('Starter')) &&
          !feat.hidden.includes(plan)
        );
      });
      if (this.isDesktop && !this.showMoreOpen) {
        // on desktop by default we display some feature even if show more is not true
        // it will have a total max of features according to the value set in SB
        return features.slice(0, parseInt(this.maxFeatures));
      }
      return features;
    },
    replaceSpecialCharacters(value) {
      return value.replace(/(&amp;|&|\(|\))/g, '');
    },
    hideTooltip(event) {
      // hide tooltip on element
      event.target.parentElement.parentElement.classList.remove('tooltip');
    },
    mouseOver(event) {
      const featuresHovered = document.querySelectorAll(
        `[data-feature="${this.replaceSpecialCharacters(
          event.target.innerHTML
        )}"]`
      );
      featuresHovered.forEach(feature => {
        feature.parentElement.classList.add('hovered');
      });
      // show tooltip on element
      event.target.parentElement.parentElement.classList.add('tooltip');
    },
    mouseLeave(event) {
      const featuresHovered = document.querySelectorAll(
        `[data-feature="${this.replaceSpecialCharacters(
          event.target.innerHTML
        )}"]`
      );
      featuresHovered.forEach(feature => {
        feature.parentElement.classList.remove('hovered');
      });
      this.hideTooltip(event);
    },
    tapHandler() {
      return (direction, event) => {
        this.hideTooltip(event);
      };
    },
  },
};
