
export default {
  name: 'g-tour-number',
  props: {
    number: {
      type: Number,
      required: true,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
};
