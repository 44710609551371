
import GSpacer from '../g-spacer';
import GArrow from '../g-arrow';
import GScrollTo from '../g-scroll-to';
import GTourNumber from './g-tour-number';

export default {
  name: 'g-tour-card',
  components: {
    GSpacer,
    GArrow,
    GScrollTo,
    GTourNumber,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
