
import GNavigationBody from './g-navigation-body';
import GButtonV2 from '../g-button-v2';
import parseLinkHref from '~/utils/parseLinkHref';
import { kebabize } from '~/utils/urlHelpers';

export default {
  name: 'g-navigation',
  components: {
    GNavigationBody,
    iconTriangle: () => import('assets/svg/icons/icon-dropdown.svg'),
    GButtonV2,
    iconArrowDown: () => import('assets/svg/icons/icon-arrow-small-down.svg'),
    logoDeputy: () => import('assets/svg/icons/logo-deputy.svg'),
    logoDeputyMobile: () => import('assets/svg/icons/logo-deputy-mobile.svg'),
    iconBackPurple: () => import('assets/svg/icons/icon-back-purple.svg'),
  },
  props: {
    contents: {
      type: Array,
      required: true,
      default: null,
    },
    buttons: {
      type: Array,
      required: true,
      default: null,
    },
    headerClass: {
      type: String,
      required: true,
    },
    logoHomeUrl: {
      type: String,
      default: '/',
    },
    headerText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      openMenuIndex: 0,
      hamburgerMenuOpen: false,
      isEmpty: false,
      showDesktopMenu: false,
      showMobileMenu: false,
    };
  },
  computed: {
    getStyles() {
      let padding = '130px';

      if (typeof window !== 'undefined') {
        padding = window.innerHeight < 610 ? '160px' : '130px';
        if (window.innerHeight < window.outerHeight) {
          padding = '120px';
        }
      }
      return {
        '--padding-bottom': padding,
      };
    },
    noNavigation() {
      return {
        'g-navigation-none': this.headerClass === 'header-none',
      };
    },
    defaultHeader() {
      return (
        this.headerClass === 'header-logo-cta' ||
        this.headerClass === 'header-logo-cta-custom' ||
        this.headerClass === 'default-header'
      );
    },
    bindClass() {
      return {
        'g-navigation__simple-nav-desktop':
          this.headerClass === 'header-logo-cta',
        'g-navigation__simple-nav-mobile':
          this.headerClass === 'header-logo-cta',
        'g-navigation__simple-nav-desktop-cta-custom':
          this.headerClass === 'header-logo-cta-custom',
        'g-navigation__simple-nav-mobile-cta-custom':
          this.headerClass === 'header-logo-cta-custom',
        'g-navigation__simple-nav-desktop-logo-only':
          this.headerClass === 'header-logo-only',
        'g-navigation__simple-nav-mobile-logo-only':
          this.headerClass === 'header-logo-only',
        'g-navigation__simple-nav-logo-title-home-link':
          this.headerClass === 'logo-title-home-link',
      };
    },
    hasContent() {
      return (
        this?.contents[this?.openMenuIndex]?.menu_columns?.length > 0 &&
        this?.contents[this?.openMenuIndex]?.menu_featured_items?.length > 0
      );
    },
    withoutLink() {
      return this.headerClass === 'header-logo-only-without-link';
    },
  },
  watch: {
    openMenuIndex(newVal) {
      this.isOpen = false;
      this.toggleMenu(newVal);
    },
  },
  mounted() {
    this.$root.$on('click-outside', value => {
      if (value && this.isOpen) {
        this.closeMenu();
      }
    });
  },
  methods: {
    modalTarget(target) {
      if (target === '#signupModal' || target === '#bookADemo') {
        return target;
      }
      return '';
    },
    buttonId(target, screen) {
      if (target === '#signupModal' || target === '#bookADemo') {
        return `${screen}_nav`;
      }
    },
    // convert the old GButton sizes to the new GButtonV2 sizes
    convertButtonSize(oldSize) {
      switch (oldSize) {
        case 'sm':
          return 'sm';
        case 'lg':
          return 'md';
        case 'xl':
          return 'lg';
        default:
          return 'md';
      }
    },
    // convert the old GButton type prop to the new GButtonV2 props
    convertButtonStyle(oldType) {
      switch (oldType) {
        case 'purple':
          return {};
        case 'purple-outline':
          return {
            type: 'outline',
          };
        case 'bright-teal':
          return {
            theme: 'azure',
          };
        case 'bright-teal-outline':
          return {
            theme: 'azure',
            type: 'outline',
          };
        case 'spark-outline':
          return {
            color: 'peach-40',
            type: 'outline',
          };
        case 'white':
          return {
            color: 'azure-10',
            secondaryColor: 'purple-50',
          };
        case 'white-outline':
          return {
            color: 'grey-10',
            type: 'outline',
          };
        case 'text':
          return {
            type: 'text',
          };
        case 'text-underline':
          return {
            type: 'underline',
          };
        default:
          return {};
      }
    },
    // This id is added to the button's `data-cta-id` attribute to identify the
    // CTAs and allow us to handle them for experiments and client side modifications
    ctaId(button, screen) {
      let targetId = '';
      // check if the button has a `cta_id` already set
      if (button.hasOwnProperty('cta_id') && button?.cta_id !== '') {
        targetId = button.cta_id;
        // if not, try to generate one based on the button target
      } else if (
        button.button_target === '#signupModal' ||
        button.button_target === '#bookADemo'
      ) {
        targetId = kebabize(button.button_target.replace('#', ''));
      }
      return targetId ? `exp_nav_${screen}_${targetId}` : null;
    },
    // when click on mobile menu every where in mobile screen, we see the top of the menu features
    scrollToTop() {
      this.$nextTick(() => {
        const ele = document.querySelector(
          '.g-navigation-mobile-menu--is-open'
        );
        ele.scrollIntoView(false);
      });
    },
    getButtonsByPosition(position = 'header') {
      return this.buttons.filter(button => button.button_position === position);
    },
    toggleMenu(index) {
      this.$root.$emit('click-outside', false);
      if (!this.$isMaxTabletLarge && this.isOpen) {
        this.removeClassActive();
      }
      this.openMenuIndex = index;
      if (this.isOpen) {
        this.closeMenu();
      } else {
        this.showDesktopMenu = true;
        this.isOpen = true;
        this.isContentEmpty(index);
        if (this.hasContent) {
          this.scrollToTop();
          this.addClassActive();
        }
      }
    },
    hasMenuItems(content) {
      return (
        content.menu_featured_items.length > 0 &&
        content.menu_columns.length > 0
      );
    },
    isContentEmpty(index) {
      this.isEmpty =
        this?.contents[index]?.menu_featured_items?.length === 0 &&
        this?.contents[index]?.menu_columns?.length === 0;
    },
    toggleMobileMenu() {
      if (this.hamburgerMenuOpen) {
        this.toggleDriftButton();
        this.closeMobileMenu();
        this.closeMenu();
        this.removeStickyNavOnMobile();
      } else {
        this.showMobileMenu = true;
        this.calculateViewHeight();
        this.hamburgerMenuOpen = true;
        this.closeMenu();
        this.stickyNavOnMobile();
        this.toggleMobileMenuHamburger();
        this.toggleDriftButton(true);
      }
    },
    toggleDriftButton(hide = false) {
      // hide drift
      if (this.$store) {
        this.$nextTick(() => {
          this.$store.commit('hideDrift', hide);
        });
      }
    },
    closeMenu() {
      this.isOpen = false;
      if (!this.$isMaxTabletLarge) {
        this.removeClassActive();
      }
    },
    closeMobileMenu() {
      this.hamburgerMenuOpen = false;
      this.isOpen = false;
      this.removeToggleMobileMenuHamburger();
    },
    addClassActive() {
      this.$refs['active'][this.openMenuIndex].classList.add('active');
    },
    removeClassActive() {
      this.$refs['active'][this.openMenuIndex].classList.remove('active');
    },
    stickyNavOnMobile() {
      this.$refs['stickyNavBottom'].classList.add('stickyBottom');
      document.documentElement.style.setProperty('overflow', 'hidden');
    },
    removeStickyNavOnMobile(property) {
      this.$refs['stickyNavBottom'].classList.remove('stickyBottom');
      document.documentElement.style.removeProperty('overflow');
    },
    toggleMobileMenuHamburger() {
      this.$refs['toggle'].classList.add('toggleHamburgerMenu');
    },
    removeToggleMobileMenuHamburger() {
      this.$refs['toggle'].classList.remove('toggleHamburgerMenu');
    },
    buttonPosition(position) {
      return `g-navigation__whole-container-header-right-${position}`;
    },
    calculateViewHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    parseLinkHref,
  },
};
