
import GLazyImage from '../g-lazy-image';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'g-navigation-body',
  components: {
    GLazyImage,
    iconArrow: () => import('assets/svg/icons/icon-arrow-smal.svg'),
  },
  props: {
    navBodyMenuItems: {
      type: Array,
      required: true,
    },
    navBodyMenuItemFeatures: {
      type: Array,
      required: true,
    },
    titleFeaturedMenu: {
      type: String,
      required: true,
    },
    titleInternalMenu: {
      type: String,
      required: true,
    },
  },
  methods: {
    parseLinkHref,
    showSubline(column, subline) {
      return column.menu_items.length < 5 && subline;
    },
  },
};
