
export default {
  name: 'g-lottie-animation',
  components: {
    GLazyImage: () => import('../g-lazy-image'),
    Lottie: () => import('./lottie'),
  },
  props: {
    placeholderImage: {
      type: Object,
      default: null,
    },
    loadPlaceholderImmediately: {
      type: Boolean,
      default: false,
    },
    animationJsonUrl: {
      type: String,
      default: '',
    },
    mobileImage: {
      type: Object,
      default: null,
    },
    placeholderImgSizeSm: {
      type: Number,
      default: null,
    },
    placeholderImgSizeMd: {
      type: Number,
      default: null,
    },
    placeholderImgSizeLg: {
      type: Number,
      default: null,
    },
    placeholderImgSizeXl: {
      type: Number,
      default: null,
    },
    placeholderWidth: {
      type: Number,
      default: null,
    },
    placeholderHeight: {
      type: Number,
      default: null,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    await this.fetchData();
  },
  fetchOnServer: false,
  data() {
    return {
      showAnimation: false,
      animationData: null,
    };
  },
  computed: {
    finalImage() {
      // this needs to be computed as $isTablet is undefined when data() is initalized
      return this.mobileImage?.imagePublicUrl && !this.$isTablet
        ? this.mobileImage
        : this.placeholderImage;
    },
  },
  mounted() {
    // we are doing this because fetch is not called in storybook
    if (process.env.NODE_ENV === 'development') {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      // we don't want to load lottie animation on mobile
      if (this.$isTablet) {
        const src = this.animationJsonUrl.replace(
          'https://a.storyblok.com',
          'https://s3.amazonaws.com/a.storyblok.com'
        );
        this.animationData = await fetch(src).then(res => res.json());
        this.showAnimation = true;
      }
    },
  },
};
